<app-spot-spaces-form (spotSpacesRequest)="onSubmitSpotSpacesRequest($event)"></app-spot-spaces-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5">
    <h1>Parkraum Stammdaten für Parkspotting</h1>
</div>
    
<div class="flex flex-col w-[calc(100%-2.5rem)] px-5 mb-20">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 spot-spaces-table">

        <!-- Space Column -->
        <ng-container matColumnDef="space">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Parkraum </th>
            <td mat-cell *matCellDef="let element" class="!text-left max-w-[18rem]">
                <b>{{element.space.name}}</b><br>
                <code>{{element.space.id}}</code>
            </td>
        </ng-container>

        <!-- Timestamp Column -->
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Zeit </th>
            <td mat-cell *matCellDef="let element" class="!text-left">
                <b>{{element.timestamp | date: 'dd.MM.Y'}}</b><br>
                {{element.timestamp | date: 'HH:mm:ss'}} Uhr
            </td>
        </ng-container>

        <!-- Capacity Total Column -->
        <ng-container matColumnDef="capacityTotal">
            <th mat-header-cell *matHeaderCellDef> Kapazität </th>
            <td mat-cell *matCellDef="let element">{{element.capacityTotal}}</td>
        </ng-container>

        <!-- Season Parker Reservation Column -->
        <ng-container matColumnDef="seasonParkerReservations">
            <th mat-header-cell *matHeaderCellDef> Reservierung Dauerparker </th>
            <td mat-cell *matCellDef="let element">{{element.seasonParkerReservations}}</td>
        </ng-container>

        <!-- Model Day Column
        <ng-container matColumnDef="modelDay">
            <th mat-header-cell *matHeaderCellDef> Model Day </th>
            <td mat-cell *matCellDef="let element">{{element.modelDay}}</td>
        </ng-container> -->

        <!-- Season Parker VSS Column -->
        <ng-container matColumnDef="seasonParkerVss">
            <th mat-header-cell *matHeaderCellDef> Dauerparker VSS </th>
            <td mat-cell *matCellDef="let element">{{element.seasonParkerVss}}</td>
        </ng-container>

        <!-- Occupancy Season Parker Column -->
        <ng-container matColumnDef="occupancySeasonParker">
            <th mat-header-cell *matHeaderCellDef> Anzahl Dauerparker </th>
            <td mat-cell *matCellDef="let element">{{element.occupancySeasonParker}}</td>
        </ng-container>

        <!-- PS App ID Column -->
        <ng-container matColumnDef="psAppId">
            <th mat-header-cell *matHeaderCellDef> PS App ID </th>
            <td mat-cell *matCellDef="let element"><code>{{element.psAppId}}</code></td>
        </ng-container>

        <!-- PSA IDs Column -->
        <ng-container matColumnDef="psaIds">
            <th mat-header-cell *matHeaderCellDef> PSA IDs </th>
            <td mat-cell *matCellDef="let element" class="text-right whitespace-pre-wrap break-words max-w-[16rem]">
                <code>{{element.psaIds.join(', ')}}</code>
            </td>
        </ng-container>

        <!-- Special Agreements Column -->
        <ng-container matColumnDef="specialAgreements">
            <th mat-header-cell *matHeaderCellDef> Sondertarife </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon>{{element.specialAgreements ? 'check' : 'close'}}</mat-icon>
            </td>
        </ng-container>

        <!-- Tariff Hour Column -->
        <ng-container matColumnDef="tariffHour">
            <th mat-header-cell *matHeaderCellDef> Tarif Stundenticket </th>
            <td mat-cell *matCellDef="let element">{{element.tariffHour | currency: 'EUR' : 'symbol' : '1.2-2'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10" class="!text-center">Keine Daten gefunden.</td>
        </tr>

    </table>

</div>