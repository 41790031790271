<app-spot-cluster-form (spotClusterRequest)="onSubmitSpotClusterRequest($event)"></app-spot-cluster-form>

<div class="flex flex-row w-[calc(100%-2.50rem)] items-center bg-[whitesmoke] sticky top-[60px] p-5">
    <h1>Aktuelle Parkspotting Cluster vom {{timestamp | date: 'dd.MM.Y'}}</h1>
</div>

<div class="flex flex-col w-[50%] px-5 mb-5">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 countedOccupancies-table">

        <!-- Space ID Column -->
        <ng-container matColumnDef="spaceId">
            <th mat-header-cell *matHeaderCellDef class="!text-left"> Parkraum ID </th>
            <td mat-cell *matCellDef="let element" class="!text-left">
                <code>{{element.spaceId}}</code>
            </td>
        </ng-container>

        <!-- Commuter Column -->
        <ng-container matColumnDef="commuter">
            <th mat-header-cell *matHeaderCellDef class="!text-right"> Pendler </th>
            <td mat-cell *matCellDef="let element" class="!text-right">{{element.commuter | number: '1.2-6' : 'de'}}</td>
        </ng-container>

        <!-- High Frequency Column -->
        <ng-container matColumnDef="highFrequency">
            <th mat-header-cell *matHeaderCellDef class="!text-right"> Hochfrequenz </th>
            <td mat-cell *matCellDef="let element" class="!text-right">{{element.highFrequency | number: '1.2-6' : 'de'}}</td>
        </ng-container>

        <!-- Saturday Column -->
        <ng-container matColumnDef="saturday">
            <th mat-header-cell *matHeaderCellDef class="!text-right"> Samstagseinkauf </th>
            <td mat-cell *matCellDef="let element" class="!text-right">{{element.saturday | number: '1.2-6' : 'de'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4" class="!text-center">Keine Daten gefunden.</td>
        </tr>

    </table>

</div>