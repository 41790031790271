import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';

@Component({
	selector: 'app-spot-cluster',
	templateUrl: './spot-cluster.component.html',
	styleUrls: ['./spot-cluster.component.scss']
})
export class SpotClusterComponent implements OnInit {

	timestamp: string = '';

	displayedColumns: string[] = [
		'spaceId',
		'commuter',
		'saturday',
		'highFrequency'
	]
	dataSource = new MatTableDataSource<any>();

	constructor(
		private dataService: DataService
	) { }

	ngOnInit(): void {
		this.updateSpotClusters();
	}

	updateSpotClusters(): void {
		this.dataService.getSpotClusters().subscribe(
            (data: any) => {
                this.dataSource = data.parameters;
				this.timestamp = data.timestamp;
            }
        )
	}

	onSubmitSpotClusterRequest(event: any): void {
		if ( event ) this.updateSpotClusters();
	}

}
