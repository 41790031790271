import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '@core/services/data.service';

@Component({
  selector: 'app-spot-spaces',
  templateUrl: './spot-spaces.component.html',
  styleUrls: ['./spot-spaces.component.scss']
})
export class SpotSpacesComponent implements OnInit {

	displayedColumns: string[] = [
		'spaceId',
		'timestamp',
		'capacityTotal',
		'occupancySeasonParker',
		'seasonParkerReservations',
		'seasonParkerVss',
		'tariffHour',
		'specialAgreements',
		'psAppId',
		'psaIds',
	]
	dataSource = new MatTableDataSource<any>();

	constructor(
		private dataService: DataService
	) { }

	ngOnInit(): void {
		this.updateSpotSpaces({size: 1000});
	}

	updateSpotSpaces(params: any): void {
		this.dataService.getSpotSpaces(params).subscribe(
            (data: any) => {
                this.dataSource = data.content;
            }
        )
	}

	onSubmitSpotSpacesRequest(event: any): void {
		if ( event ) this.updateSpotSpaces({size: 1000, timestamp: event.date});
	}

}
