import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { default as ChartDataLabels } from 'chartjs-plugin-datalabels';
import { DataService } from '@core/services/data.service';
import { forkJoin } from 'rxjs';

import * as moment from 'moment';


@Component({
	selector: 'app-parkspotting-comparison',
	templateUrl: './parkspotting-comparison.component.html',
	styleUrls: ['./parkspotting-comparison.component.scss']
})
export class ParkspottingComparisonComponent implements OnInit, OnDestroy {

	@Input() data: any
	@Input() delay: number	
	
	interval: number;
	
	displayedColumns: string[] = [
		'site',
		'capacity',
		'currentOccupancy',
		'vacancy',
		'currentOccupancyChart',
		'occupanciesChart',
		'timestamp'
	]
	dataSource = new MatTableDataSource<any>();

	occupanciesChartLabels: Label[];
	// occupanciesChartData: ChartDataSets[];
	occupanciesChartType: ChartType = 'line';
	occupanciesChartLegend = false;

	occupanciesChartOptions: ChartOptions = {
		responsive: false,
		elements: {
			line: {
				borderWidth: 1.5
			},
			point: {
				radius: 0
			}
		},
		tooltips: {
			enabled: false,
			mode: 'index',
			intersect: false
		},
		layout: {
			padding: 10
		},
		scales: {
			yAxes: [{ 
				display: false,
				ticks: {
					beginAtZero: true,
					min: 0,
					max: 100,
					stepSize: 1
				}
			 }],
			xAxes: [{ display: false }]
		}
	};


	currentOccupnacyChartLabels: Label[] = ['KZP', 'Monat', 'Frei'];
	currentOccupnacyChartType: ChartType = 'horizontalBar';
	currentOccupnacyChartLegend = false;
	currentOccupnacyChartOptions: ChartOptions = {
		maintainAspectRatio: true,
		responsive: false,
		tooltips: {
			enabled: false
		},
		layout: {
			padding: 0
		},
		scales: {
			yAxes: [{
				display: true,
				gridLines: {
					display: false
				}
			}],
			xAxes: [{ 
				display: false,
				ticks: {
					beginAtZero: true,
					min: 0,
					max: 100,
					stepSize: 1
				}
			 }]
		},
		/*animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize,
		showLines: false // disable for all datasets*/
	};

	barChartPlugins = [
		ChartDataLabels
	];

	constructor(
		private dataService: DataService
	) { }

	ngOnInit(): void {

		this.updateParkspotting();
        this.interval = window.setInterval(() => { this.updateParkspotting() }, this.delay)
	}

    ngOnDestroy(): void {
        if (this.interval) window.clearInterval(this.interval)
    }

	updateParkspotting(): void {

		forkJoin([
			this.dataService.getDashboardParkspotting(),
			this.dataService.getOccupancies()
		]).subscribe(response => {

			const dashboard = response[0]
			const occupancies = response[1]

			// Create each dataset for chartjs
			dashboard.entries.map((entry: any) => {
				entry.currentOccupnacyChartDataSets = this.createCurrentOccupancyDataset(entry.currentOccupancy)
				entry.occupanciesChartDataSets = this.createOccupanciesDataset(entry.occupancies)
				entry.currentOccupancy.hasDowntime = this.hasDowntime(entry.currentOccupancy.timeSegment)
			})

			this.dataSource.data = dashboard.entries;  
			// Merge occupancy category into dashboard stream
			this.dataSource.data.map((entry: any) => {
				occupancies.map((item:any) => {
					if (item.site.id === entry.site.id) entry.currentOccupancy.occupancyCategory = item.occupancy.occupancyCategory
				})
			})
			// Build chart labels from dashboard timestamp
			this.occupanciesChartLabels = this.buildTimeSegments(dashboard.from, dashboard.until)

			//	console.log(this.dataSource.data)
		})
	}
	

	createCurrentOccupancyDataset(currentOccupancy: any): ChartDataSets[] {

		let shortTermPercentage: number = currentOccupancy.occupancyShortTermRate === null ? currentOccupancy.occupancyShortTermRate: Math.round(currentOccupancy.occupancyShortTermRate * 100);
		
		let longTermPercentage: number = currentOccupancy.occupancyLongTermRate === null ? currentOccupancy.occupancyLongTermRate : Math.round(currentOccupancy.occupancyLongTermRate  * 100);
		
		let vacancyPercentage: number = Math.round(
			( (currentOccupancy.capacityTotal - currentOccupancy.occupancyTotal) * 100 ) / currentOccupancy.capacityTotal
		)

		const currentOccupancies = [
			currentOccupancy.occupancyShortTerm, 
			currentOccupancy.occupancyLongTerm, 
			(currentOccupancy.capacityTotal - currentOccupancy.occupancyTotal)
		];

		return [{
			barPercentage: 1,
			categoryPercentage: 1,
			backgroundColor: ['#ff8d60', '#7ac2ff', '#e1e1e1'],
			data: [shortTermPercentage, longTermPercentage, vacancyPercentage],
			datalabels: {
				color: '#555555',
				align: 'start',
				anchor: 'start',
				clamp: true,
				offset: -70,
				display: true,
				formatter: (value: any, context: any) => {
					return `${value}% (${currentOccupancies[context.dataIndex]})`
				}
			}
		}]
	}

	createOccupanciesDataset(occupancies: any[]): ChartDataSets[] {

		let longTermArray: number[] = occupancies.map(occupancy =>
			occupancy.occupancyLongTermRate === null ? occupancy.occupancyLongTermRate : Math.round(occupancy.occupancyLongTermRate * 100)
		)

		let shortTermArray: number[] = occupancies.map(occupancy => 
			occupancy.occupancyShortTermRate === null ? occupancy.occupancyShortTermRate : Math.round(occupancy.occupancyShortTermRate * 100)
		)

		return [{
			borderColor: '#ff8d60',
			fill: false,
			data: shortTermArray
		}, {
			borderColor: '#7ac2ff',
			fill: false,
			data: longTermArray
		}]
	}

	/** TODO: Do better job - maybe service? */
	buildTimeSegments(from: string, until: string): string[] {
        const timeSegments = [];
        let start = moment(from);
        let end = moment(until);
        while ( start <= end )  {
            timeSegments.push(moment(start).format('HH:mm'));
            start.add(1, 'hours');
        }

        return timeSegments;
    }

	/** TODO: Do better job - maybe service? */
	hasDowntime(timeSegment: string): boolean {

		if ( moment(timeSegment).isValid() ) {
			const now = moment();
			const duration = moment.duration(now.diff(timeSegment));
			const hours = duration.asHours();

			if ( hours >= 5 ) return true
			
			return false
		}

		return false
	}

}
